import service from '@/utils/services'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()
const today = utils.getToday()

const state = {
  isMobile: isMobile,
  isIPhone: isIPhone,
  today: today,
  zhuziAppId: window.zhuziAppId,
  homeBannerPid: 18,
  mHomeBannerPid: 21,
  homeGameimgPid: 19,
  mHomeGameimgPid: 20,
  basicData: {
    address: '',
    company: '',
    copyright: '',
    description: '',
    keywords: '',
    record_number: '',
    title: '',
  },
  footLinks: [
    { id: 1, name: '隐私政策', url: '/news?id=21' },
    { id: 2, name: '服务条款', url: '/news?id=22' },
    { id: 3, name: '关于我们', url: '/about' },
    { id: 4, name: '联系我们', url: 'mailto:zhuzi@zhuziplay.com?subject=contact' }
  ],
  gameUserInfo: {
    user_id: '',
    username: '',
    realname: '',
    mobile: '',
    token: ''
  },
  games: [
    {
      app_id: 1029,
      title: '仙宗大掌门',
      banner: 'https://static.zhuziplay.com/office/image/cr8d7h2gdos2hidoni.png',
      listImage: 'https://static.zhuziplay.com/office/image/cr8g6jfam9j68dyphh.jpg',
      desc: '是一款以修真仙侠为背景的模拟经营类手机网络游戏。在游戏中，玩家将从一名临危受命的仙宗新任掌门开始在修真界通过自己的努力，通过奋斗实现壮大仙宗的梦想。',
      content: '<p>《仙宗大掌门》是一款以修真仙侠为背景的模拟经营类手机网络游戏。 在游戏中，玩家将从一名临危受命的仙宗新任掌门开始，在修真界通过自己的努力，从打造法宝装备开始，不断招揽志同道合的伙伴，并带领他们与因私欲野心而作乱的邪魔之辈对抗，更与修真大千世界的诸多掌门竞争，最后一步步成长为修真界的最强掌门，铲除魔乱平定修真世界的祸乱， 通过奋斗实现壮大仙宗的梦想。<br><br>玩家可以通过打造制作这一核心功能，获得各种精良优秀的法宝装备用于商业交易或自身装备，获得更多支持发展的资源道具，与此同时还可以通过招揽优秀的英雄和工匠，不断提高仙宗的战斗实力和生产效率，使打造这一核心功能又能够反哺辅助于各个系统玩法。<br><br>除此之外，主线闯荡、竞技场、通天之路等玩法，可以为玩家带来提升各种培养的道具和货币资源，为主角打造一个强盛的仙宗提供助力。 通过游戏，玩家能获得经营策略思维的锻炼，在体验壮大仙宗并为之奋斗打拼的乐趣之余，在与诸多玩家掌门竞力争先感受到团结协作齐奋进的意义，还能通过游戏中与破坏秩序的邪魔之辈斗争作战，领略到维护正义和平的使命责任感。</p><p><br></p>',
      url: 'https://xzdzm.zhuziplay.com/index'
    },
  ],
  storedData: [
    { id: 1, name: 'alipaly', image: 'https://static.zhuziplay.com/office/assets/images/ali_pay.png' },
    { id: 2, name: 'wxpay', image: 'https://static.zhuziplay.com/office/assets/images/wx_pay.png' }
  ],
  payState: [
    { id: -1, name: '全部状态' },
    { id: 0, name: '未支付' },
    { id: 1, name: '正在发货' },
    { id: 2, name: '发货成功' },
    { id: 3, name: '支付失败' },
    { id: 4, name: '补单中' },
    { id: 5, name: '补单成功' },
    { id: 6, name: '发货失败' },
    { id: 7, name: '发货失败' }
  ],
  items: [
    { item_id: 'com.zhuziplay.xz.zz.60',	item_name: '60仙玉', item_price:  6, currency: 60, delivery: 0 },
    { item_id: 'com.zhuziplay.xz.zz.300',	item_name: '300仙玉', item_price:  30, currency: 300, delivery: 0 },
    { item_id: 'com.zhuziplay.xz.zz.980',	item_name: '980仙玉', item_price:  98, currency: 980, delivery: 0 },
    { item_id: 'com.zhuziplay.xz.zz.1920',	item_name: '1920仙玉', item_price:  192, currency: 1920, delivery: 0 },
    { item_id: 'com.zhuziplay.xz.zz.3280',	item_name: '3280仙玉', item_price:  328, currency: 3280, delivery: 0 },
    { item_id: 'com.zhuziplay.xz.zz.6480',	item_name: '6480仙玉', item_price:  648, currency: 6480, delivery: 0 },
  ]
}

const getters = {
  isMobile: state => state.isMobile,
  isIPhone: state => state.isIPhone,
  today: state => state.today,
  zhuziAppId: state => state.zhuziAppId,
  homeBannerPid: state => state.homeBannerPid,
  mHomeBannerPid: state => state.mHomeBannerPid,
  homeGameimgPid: state => state.homeGameimgPid,
  mHomeGameimgPid: state => state.mHomeGameimgPid,
  basicData: (state) => {
    if (state.basicData.copyright === '') {
      let basicData = storage.get('basicData')
      if (basicData !== null) {
        state.basicData = JSON.parse(basicData)
      }
    }
    return state.basicData
  },
  footLinks: state => state.footLinks,
  gameUserInfo: (state) => {
    if (state.gameUserInfo.token === '') {
      let gameUserInfo = storage.get('gameUserInfo')
      if (gameUserInfo !== null) {
        state.gameUserInfo = JSON.parse(gameUserInfo)
      }
    }
    return state.gameUserInfo
  },
  payState: state => state.payState,
  storedData: state => state.storedData,
  games: state => state.games,
  items: state => state.items,
}

const mutations = {
  updateBasicData (state, params) {
    storage.set('basicData', JSON.stringify(state.basicData))
  },
  updateGameUserInfo (state, params) {
    storage.set('gameUserInfo', JSON.stringify(state.gameUserInfo))
  }
}

const actions = {
  getSiteDetail ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/siteConfig/get', { id: window.siteConfigId }).then((res) => {
        if (res.data.code === 0) {
          var basicData = {
            address: res.data.data.address,
            company: res.data.data.company,
            copyright: res.data.data.copyright,
            description: res.data.data.description,
            keywords: res.data.data.keywords,
            record_number: res.data.data.record_number,
            title: res.data.data.title,
          }
          state.basicData = basicData
          commit('updateBasicData', basicData)
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  getNewsDetail ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/information/get', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBannerList ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/banner/list', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziUserLogin ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login/account', { act: true, username: params.account, password: params.password }).then((res) => {
        if (res.data.code === 0) {
          var gameUserInfo = {
            username: res.data.data.username,
            realname: res.data.data.realname,
            mobile: res.data.data.mobile,
            token: res.data.data.token,
            user_id: res.data.data.user_id
          }
          state.gameUserInfo = gameUserInfo
          commit('updateGameUserInfo', gameUserInfo)
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziUserRegister ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/reg', { act: true, username: params.account, password: params.password }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          var gameUserInfo = {
            username: res.data.data.username,
            realname: res.data.data.realname,
            mobile: res.data.data.mobile,
            token: res.data.data.token,
            user_id: res.data.data.user_id
          }
          state.gameUserInfo = gameUserInfo
          commit('updateGameUserInfo', gameUserInfo)
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetZhuziOrderList ({ commit },params) {
    return new Promise((resolve, reject) => {
      service.post('/order/list', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  UserOut ({ commit }, params) {
    state.gameUserInfo = params
    commit('updateGameUserInfo', params)
  },
  GetZhuziOrderSign ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/cpOrder', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziOrderCreateAlipay ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/create/alipay', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziOrderCreateWxpay ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/create/wxpay', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

