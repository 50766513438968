import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/views/BaseLayout';
import AccountDoc from '@/views/mobile/AccountDoc';
import PrivacyDoc from '@/views/mobile/PrivacyDoc';

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: BaseLayout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import('@/views/HomeView')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/NewsView')
      },
      {
        path: '/query',
        name: 'query',
        component: () => import('@/views/QueryView')
      },
      {
        path: '/orderQuery',
        name: 'orderQuery',
        component: () => import('@/views/OrderQueryView')
      },
      {
        path: '/stored',
        name: 'stored',
        component: () => import('@/views/StoredView')
      },
      {
        path: '/gameZone',
        name: 'gameZone',
        component: () => import('@/views/GameZoneView')
      }
    ]
  },
  {
    path: '/account/doc',
    name: 'accountDoc',
    component: AccountDoc
  },
  {
    path: '/privacy/doc',
    name: 'privacyDoc',
    component: PrivacyDoc
  },
  {
    path: '/fans',
    name: 'fans',
    component: () => import('@/views/FansView')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/mobile/UserLogin')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/mobile/UserRegister')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
