<template>
  <div class="mobile-footbar" v-if="isMobile">
    <div class="img">
      <img src="@/assets/images/logo.png" alt>
      <img src="@/assets/images/icon.png" alt>
    </div>
    <p>
      <a v-for="(item,index) in footLinks" :key="index" :href="item.url">{{ item.name }}</a>
    </p>
    <p>公司地址：{{ basicData.address }}</p>
    <p>{{ basicData.copyright }}</p>
    <p v-html="basicData.record_number">{{ basicData.record_number }}</p>
  </div>
  <div class="footbar" v-else>
    <div class="left">
      <div class="img">
        <img src="@/assets/images/logo.png" alt>
        <img src="@/assets/images/icon.png" alt>
      </div>
      <div class="link">
        <a v-for="(item,index) in footLinks" :key="index" :href="item.url">{{ item.name }}</a>
      </div>
    </div>
    <div class="foot_center"></div>
    <div class="right">
      <p>公司地址：{{ basicData.address }}</p>
      <p>{{ basicData.copyright }}</p>
      <p v-html="basicData.record_number">{{ basicData.record_number }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'footBar',
  data () {
    return {}
  },
  computed:{
    ...mapGetters(['isMobile','basicData','footLinks']),
  },
}
</script>

<style lang="scss">

</style>
