<template>
  <div id="login" :class="isMobile?'mobile-login':'login'">
    <form>
      <div class="form_control">
        <input v-model="formData.account" type="account" placeholder="请输入账号" class="form_input_account"/>
        <span class="tip" :hidden="tip_account"><img src="@/assets/images/tips.png" alt>{{ tip_text }}</span>
      </div>

      <div class="form_control">
        <input v-model="formData.password" type="password" autocomplete placeholder="请输入密码，至少6位" class="form_input_password"/>
        <span class="tip" :hidden="tip_password"><img src="@/assets/images/tips.png" alt>{{ tip_text }}</span>
      </div>

      <div class="form_control">
        <input v-model="formData.passwordOld" type="password" autocomplete placeholder="请再次输入密码，至少6位" class="form_input_password"/>
        <span class="tip" :hidden="tip_password_old"><img src="@/assets/images/tips.png" alt>{{ tip_text }}</span>
      </div>
    </form>
    <div class="login-button"><button type="button"  @click="registerClick" class="button">注册</button></div>
    <div class="login-checkbox">
      <input type="checkbox" value="" @change="checkboxChange">我已阅读并同意
      <a href="/news?id=22" target="_blank">服务条款</a>及<a href="/news?id=21" target="_blank">隐私协议</a>
    </div>
    <div class="login-checkbox">如果您已经注册过账户，请前往<a @click="loginClick">登录</a></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import md5 from 'md5'

export default {
  name: 'userRegister',
  data () {
    return {
      tip_text: '',
      tip_account: true,
      tip_password: true,
      tip_password_old: true,
      isCheck: false,
      formData: {
        account: '',
        password: '',
        passwordOld: ''
      },
      loginRules: {
        account: [{ required: true, trigger: "blur", message: "用户名不能为空" }],
        password: [{ required: true, trigger: "blur", message: "密码不能为空" }],
        passwordOld: [{ required: true, trigger: "blur", message: "请再次输入密码，不能为空" }]
      },
    }
  },
  created () {
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    ...mapActions(['ZhuziUserRegister']),
    registerClick () {
      if (this.formData.account === '') {
        this.tip_text = '请输入账号，账号不能为空！'
        this.tip_account = false
        return
      } else {
        this.tip_account = true
      }
      if (this.formData.password === '') {
        this.tip_text = '请输入密码'
        this.tip_password = false
        return
      } else {
        if(this.formData.password.length<6) {
          this.tip_text = '密码至少6位，请重新输入'
          this.tip_password = false
          return
        }
        this.tip_password = true
      }
      if (this.formData.passwordOld === '') {
        this.tip_text = '请重新确认密码'
        this.tip_password_old = false
        return
      } else {
        if(this.formData.passwordOld.length<6) {
          this.tip_text = '密码至少6位，请重新确认密码'
          this.tip_password = false
          return
        }
        this.tip_password_old = true
      }

      if(this.formData.passwordOld !== this.formData.password){
        this.tip_text = '请重新确认密码，两次密码不一致'
        this.tip_password_old = false
        return
      } else {
        this.tip_password_old = true
      }

      if (!this.isCheck) {
        this.showError('请确认隐私协议选项内容')
        return
      }
      this.ZhuziUserRegister({ account: this.formData.account, password: md5(this.formData.password) }).then(res => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          this.$router.push({ name: 'home', query: {} })
        }
      }).catch(() => {
        this.showError('请重确认账号以及密码是否正确！')
      })
    },
    loginClick () {
      this.$router.push({ name: 'login', query: {} })
    },
    checkboxChange (e) {
      this.isCheck = e.target.checked
    }
  }
}
</script>

<style lang="scss">
  .tip{
    padding-left: 16px;
    font-size:12px;
    font-family:SimSun;
    color:rgba(255,64,64,1);
    line-height:30px;
  }
</style>
