<template>
  <div class="mobile-topbar" v-if="isMobile">
    <a href="/index" class="logo">
      <img src="@/assets/images/logo.png">
    </a>
    <a :class="{ active: 'index'=== currentActive }" @click="addClass('home')">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首页</a>
    <a :class="{ active: 'query'=== currentActive }" @click="addClass('query')" v-if="gameUserInfo.token">充值查询</a>
    <a :class="{ active: 'stored'=== currentActive }" @click="addClass('stored')" v-if="gameUserInfo.token">充值中心</a>
    <a :class="{ active: 'stored'=== currentActive }" @click="goLogin()" v-else>充值中心</a>
  </div>
  <div class="topbar" v-else>
      <div class="left">
        <a href="/index" class="logo">
          <img src="@/assets/images/logo.png">
        </a>
        <a :class="{ active: 'index'=== currentActive }" @click="addClass('home')" class="logo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首页</a>
        <a :class="{ active: 'query'=== currentActive }" @click="addClass('query')" v-if="gameUserInfo.token">充值查询</a>
        <a :class="{ active: 'stored'=== currentActive }" @click="addClass('stored')" v-if="gameUserInfo.token">充值中心</a>
        <a :class="{ active: 'stored'=== currentActive }" @click="goAnchor('login')" v-else>充值中心</a>
      </div>
      <div class="right" v-if="gameUserInfo.token">
        <span>欢迎您，{{gameUserInfo.username}}</span>
        <a @click="goOut()" class="logoutBtn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;退出</a>
      </div>
      <div class="right" v-else>
        <a @click="goAnchor('login')" class="loginBtn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录</a>
      </div>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'

export default {
  name: 'rechargTop',
  data () {
    return {
      currentActive: 'index',
      queryParam: {}
    }
  },
  computed: {
    ...mapGetters(['isMobile','gameUserInfo']),
  },
  created () {
    var query = window.location.pathname
    if (query.length > 0) {
      var vars = query.split('/')
      if (vars[1].length === 0) {
        this.currentActive = 'index'
      }else{
        this.currentActive = vars[1]
      }
    }
  },
  methods: {
    ...mapActions(['UserOut']),
    addClass (index) {
      this.currentActive = index
      this.$router.push({ name: index, query: this.queryParam })
    },
    //模拟锚点跳转
    goAnchor(selector) {//参数selector是id选择器（#anchor14）
      this.$router.push({ name: 'home', query: {commentId: selector} })
    },
    goLogin () {
      this.$router.push({ name: 'login', query: {} })
    },
    goOut() {
      this.UserOut({}).then((res) => {
        this.showSuccess('退出成功')
        this.currentActive = 'home'
        this.$router.push({ name: 'home', query: {} })
      })
    }
  }
}
</script>

<style lang="scss">

</style>
